<!--翻页时钟-->
<template>
    <div class="clock">
        <div class="flip">
            <div class="digital front" data-number="0"></div>
            <div class="digital back" data-number="1"></div>
        </div>
        <div class="flip">
            <div class="digital front" data-number="0"></div>
            <div class="digital back" data-number="1"></div>
        </div>
        <em class="divider">:</em>
        <div class="flip">
            <div class="digital front" data-number="0"></div>
            <div class="digital back" data-number="1"></div>
        </div>
        <div class="flip">
            <div class="digital front" data-number="0"></div>
            <div class="digital back" data-number="1"></div>
        </div>
        <em class="divider">:</em>
        <div class="flip">
            <div class="digital front" data-number="0"></div>
            <div class="digital back" data-number="1"></div>
        </div>
        <div class="flip">
            <div class="digital front" data-number="0"></div>
            <div class="digital back" data-number="1"></div>
        </div>
    </div>
</template>

<script>




    export default {
        name: "Clock",
        data() {
            return {

            };
        },
        methods: {

        },
        mounted() {
            var Flipper = /** @class */ (function () {
                function Flipper(node, currentTime, nextTime) {
                    this.isFlipping = false;
                    this.duration = 600;
                    this.flipNode = node;
                    this.frontNode = node.querySelector(".front");
                    this.backNode = node.querySelector(".back");
                    this.setFrontTime(currentTime);
                    this.setBackTime(nextTime);
                }
                Flipper.prototype.setFrontTime = function (time) {
                    this.frontNode.dataset.number = time;
                };
                Flipper.prototype.setBackTime = function (time) {
                    this.backNode.dataset.number = time;
                };
                Flipper.prototype.flipDown = function (currentTime, nextTime) {
                    var _this = this;
                    if (this.isFlipping) {
                        return false;
                    }
                    this.isFlipping = true;
                    this.setFrontTime(currentTime);
                    this.setBackTime(nextTime);
                    this.flipNode.classList.add("running");
                    setTimeout(function () {
                        _this.flipNode.classList.remove("running");
                        _this.isFlipping = false;
                        _this.setFrontTime(nextTime);
                    }, this.duration);
                };
                return Flipper;
            }());
            var getTimeFromDate = function (date) {
                return date
                    .toTimeString()
                    .slice(0, 8)
                    .split(":")
                    .join("");
            };
            var flips = document.querySelectorAll(".flip");
            var now = new Date();
            var nowTimeStr = getTimeFromDate(new Date(now.getTime() - 1000));
            var nextTimeStr = getTimeFromDate(now);
            var flippers = Array.from(flips).map(function (flip, i) { return new Flipper(flip, nowTimeStr[i], nextTimeStr[i]); });
            setInterval(function () {
                var now = new Date();
                var nowTimeStr = getTimeFromDate(new Date(now.getTime() - 1000));
                var nextTimeStr = getTimeFromDate(now);
                for (var i = 0; i < flippers.length; i++) {
                    if (nowTimeStr[i] === nextTimeStr[i]) {
                        continue;
                    }
                    flippers[i].flipDown(nowTimeStr[i], nextTimeStr[i]);
                }
            }, 1000);
        }
    }
</script>
<style lang="less" scoped>

    @import '../../assets/style/clock.css';

    .clock {
        position: relative;
        float: left;
        left: 34px;
        top: 35px;
    }

</style>
