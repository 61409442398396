<!--主页导航菜单实现-->
<template>
    <div class="navigator-div">

        <!--左侧时间-->
        <div class="left-div">{{ nowDate }}</div>
        <clock v-if="nowDate !== null"></clock>

        <!--左侧主页按钮-->
        <span>
            <img class="btn-menus-home" src="../assets/images/navigator/btn_home_normal.png"
                 @click="routerTo('/monitorIndex')"/>
        </span>
        <!--右侧下拉菜单按钮-->
        <span class="btn-menus" v-if="menuResource.find(v => v.leve2 === RESOURCE_TYPE.智能分析.value) !== undefined || menuResource.find(v => v.leve2 === RESOURCE_TYPE.点位分析.value) !== undefined">
            <img v-if="menuResource.find(v => v.leve2 === RESOURCE_TYPE.智能分析.value) !== undefined && currentMenu === RESOURCE_TYPE.智能分析.value"
                 class="btn-menus-analyse" src="../assets/images/navigator/btn_VideoEventsAnalysis_normal.png"
                 @click="menuClick(RESOURCE_TYPE.智能分析.value)" />
            <img v-if="menuResource.find(v => v.leve2 === RESOURCE_TYPE.点位分析.value) !== undefined && currentMenu === RESOURCE_TYPE.点位分析.value"
                 class="btn-menus-points" src="../assets/images/navigator/btn_HotspotsPointsAnalysis_normal.png"
                 @click="menuClick(RESOURCE_TYPE.点位分析.value)" />
        </span>
        <!--上下结构不能变,用到了css选择器,变了就失效了-->
        <panel class="menus-panel">
            <div class="menus-panel-item"
                 v-if="menuResource.find(v => v.leve2 === RESOURCE_TYPE.智能分析.value) !== undefined"
                 @click="menuClick(RESOURCE_TYPE.智能分析.value)">
                <div class="menus-panel-img">
                    <img src="../assets/images/navigator/icon_menu_znfx.png">
                </div>
                <div class="menus-panel-text">
                    视频事件智能分析
                </div>
            </div>
            <div class="menus-panel-item"
                 v-if="menuResource.find(v => v.leve2 === RESOURCE_TYPE.点位分析.value) !== undefined"
                 @click="menuClick(RESOURCE_TYPE.点位分析.value)">
                <div class="menus-panel-img">
                    <img src="../assets/images/navigator/icon_menu_zdld.png">
                </div>
                <div class="menus-panel-text">
                    重点路段点位分析
                </div>
            </div>
        </panel>

        <!--右侧操作-->
        <div class="right-div">

            <!--视频轮巡-->
<!--            <img v-if="menuResource.find(v => v.leve2 === RESOURCE_TYPE.视频轮巡.value) !== undefined" style="cursor: pointer; margin-right: 24px;" src="../assets/images/navigator/btn_nav_camera_tour.png" @click="tourVideoOpen">-->

            <span>用户名：{{userInfo.name}}</span>
            <!--<a href="#" @click.prevent="navLogout()"><img src="../assets/images/navigator/btn_nav_logout.png"></a>-->
            <a-dropdown :trigger="['click']">
                <img style="cursor: pointer" src="../assets/images/navigator/btn_nav_logout.png" @click="e => e.preventDefault()">
                <a-menu theme="dark" slot="overlay"  @click="handleMenuClick">
                    <a-menu-item key="1"> <a-icon type="user" />修改密码</a-menu-item>
                    <a-menu-item key="2"> <a-icon type="logout" />退出登录</a-menu-item>
                </a-menu>
                <a-icon slot="icon" type="user" />
            </a-dropdown>
            <a href="#" @click.prevent="switchFullScreen()"><img
                    src="../assets/images/navigator/btn_nav_fullscreen.png"></a>
        </div>

    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    import {RESOURCE_TYPE} from '../core/enums'
    import Clock from "./common/Clock"
    import {listResource} from '../api/resource'
    import Vue from 'vue'
    import Panel from '../components/common/Panel'

    export default {
        name: "MonitorNavigator",
        components: {Clock, Panel},
        data() {
            return {
                RESOURCE_TYPE,
                menuResource: [], // 菜单资源
                nowDate: null, // 当前日期
                userInfo: {},
                currentMenu: RESOURCE_TYPE.智能分析.value, // 导航栏默认展示的菜单按钮
                showMenusPanel: false // 是否显示菜单下拉面板
            };
        },
        methods: {
            ...mapActions(['Logout', 'GetUserInfo']),
            currentTime() {
                setInterval(this.formatDate, 1000);
            },
            handleMenuClick (e) {
                if (e.key === '1') {
                    this.$router.push({path: '/security'});
                }else {
                    this.navLogout()
                }
            },
            formatDate() {
                /*
                let date = new Date();
                let year = date.getFullYear(); // 年
                let month = date.getMonth() + 1; // 月
                let day = date.getDate(); // 日
                // let week = date.getDay(); // 星期
                // let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
                let hour = date.getHours(); // 时
                hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
                let minute = date.getMinutes(); // 分
                minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
                let second = date.getSeconds(); // 秒
                second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
                this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} `;
                */
                this.nowDate = this.$moment(new Date()).format('YYYY-MM-DD')
            },
            navLogout() {
                let that = this
                this.$confirm({
                    title: '温馨提示',
                    content: '确定注销当前账户吗？',
                    okText: '确定',
                    cancelText: '取消',
                    onOk() {
                        return that.Logout({}).then(() => {
                            that.$router.push({name: 'login'});
                        }).catch(err => {
                            that.$message.error({
                                title: '错误',
                                description: err.message
                            })
                        })
                    },
                    onCancel() {
                    }
                })
            },
            switchFullScreen() { // 切换全屏/非全屏
                if (document.body.scrollHeight == window.screen.height && document.body.scrollWidth == window.screen.width) { // 全屏
                    this.exitFullscreen()
                } else { // 非全屏
                    this.fullScreen()
                }
            },
            //全屏
            fullScreen() {
                let element = document.documentElement
                if (element.requestFullscreen) {
                    element.requestFullscreen()
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen()
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen()
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen()
                }
            },
            //退出全屏
            exitFullscreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen()
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen()
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen()
                }
            },
            menuClick(currentMenu) { // 点击右侧菜单按钮或下拉面板中的菜单
                this.currentMenu = currentMenu
                if (currentMenu === RESOURCE_TYPE.智能分析.value) {
                    this.routerTo('/analyse')
                } else if (currentMenu === RESOURCE_TYPE.点位分析.value) {
                    this.routerTo('/importantRoad')
                }
            },
            routerTo(link) {
                this.$router.push({path: link});
            },
            initMenuResource() { // 初始化菜单
                const roleId = Vue.ls.get('user').roleIds[0] // 角色id
                listResource(roleId).then(res => {
                    this.menuResource = res.data.filter(item => item.leve1 === 'menu' && item.leve2 !== null)
                    // 页面刷新后显示正确的菜单按钮
                    let path = this.$route.path
                    if (path === '/analyse') {
                        this.currentMenu = RESOURCE_TYPE.智能分析.value
                    } else if (path === '/importantRoad') {
                        this.currentMenu = RESOURCE_TYPE.点位分析.value
                    } else {
                        if (this.menuResource.find(v => v.leve2 === RESOURCE_TYPE.点位分析.value) !== undefined) {
                            this.currentMenu = RESOURCE_TYPE.点位分析.value
                        }
                        if (this.menuResource.find(v => v.leve2 === RESOURCE_TYPE.智能分析.value) !== undefined) {
                            this.currentMenu = RESOURCE_TYPE.智能分析.value
                        }
                    }
                })
            },
            // 打开轮巡视频面板
            tourVideoOpen () {
                this.$eventBus.$emit('tourVideoOpen')
            }
        },
        mounted() {
            this.currentTime();
            this.GetUserInfo().then((resolve) => {
                this.userInfo = resolve;
                //console.log(reject)
            }).catch(err => {
                console.log(err)
            })
            this.$nextTick(() => {
                this.initMenuResource()
            })
        },
        beforeDestroy() {
            if (this.formatDate) {
                clearInterval(this.formatDate.name); // 在Vue实例销毁前，清除时间定时器
            }
        }
    }
</script>
<style lang="less" scoped>
    .navigator-div {
        height: 71px;
        text-align: center;
        background-color: #080B45;
        background-image: url("../assets/images/navigator/pic_nav_logo.png");

        .left-div, .right-div {
            position: relative;
            top: 34px;
            height: 22px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 22px;
            color: #0088FF;
        }

        .left-div {
            float: left;
            left: 24px;
            font-size: 20px;
            font-family: lcd;
            font-weight: bold;
        }

        .right-div {
            float: right;
            right: 24px;
            font-weight: bold;

            img {
                margin-left: 24px;
                margin-bottom: 4px;
            }
        }
    }

    .btn-menus-home {
        cursor: pointer;
        position: absolute;
        left: 546px;
        top: 18px;
    }

    .btn-menus {
        cursor: pointer;
        position: absolute;
        right: 546px;
        top: 18px;

        &:hover+.menus-panel{
            display: block;
        }
    }

    .btn-menus-home:hover {
        content: url("../assets/images/navigator/btn_home_hover.png");
    }

    .btn-menus-analyse:hover {
        content: url("../assets/images/navigator/btn_VideoEventsAnalysis_hover.png");
    }

    .btn-menus-points:hover {
        content: url("../assets/images/navigator/btn_HotspotsPointsAnalysis_hover.png")
    }

    .menus-panel {
        background: rgba(21, 23, 97, 0.85);
        padding: 16px 0px;
        position: absolute;
        width: 300px !important;
        height: fit-content !important;
        top: 66px;
        right: 500px;
        z-index: 9;
        display: none;

        .menus-panel-item {
            height: 56px;
            line-height: 56px;
            cursor: pointer;

            .menus-panel-img {
                float: left;
                margin-left: 18px;
            }

            .menus-panel-text {
                float: left;
                margin-left: 12px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 800;
                color: #06CCFF;
            }

            &:hover {
                background: #114387;
            }
        }
        &:hover {
            display: block;
        }
    }
</style>
